<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <Row :gutter="20">
          <Col :lg="{span: 8, offset: 4}" :md="{span: 12}">
            <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeTime"></Date-picker>
          </Col>
          <Col :lg="{span: 3}" :md="{span: 8}">
            <Select placeholder="请选择" label-in-value v-model="type" style="text-align: left;" @on-change="handleType">
              <Option v-for="item in typeData" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </Col>
          <Col :lg="{span: 2}">
            <Button type="warning" @click="handleSearch">搜索</Button>
          </Col>
          <Col :lg="{span: 7}" :md="{span: 8}">
            <div class="tab-contain">
              <router-link class="static-button" :to="{name: 'BloodSugar'}" >统计显示</router-link>
              <router-link :to="{name: 'BloodSugarTable'}">数据显示</router-link>
              <router-link class="chart-button" :to="{name: 'BloodSugarChart'}">图表显示</router-link>
            </div>
          </Col>
        </Row>
        <!-- <div class="monitor-date">

        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'BloodSugar'}" >统计显示</router-link>
          <router-link :to="{name: 'BloodSugarTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'BloodSugarChart'}">图表显示</router-link>
        </div> -->
      </div>
    </div>
    <div class="chart" ref="sugar"></div>
  </div>
</template>
<script type="text/ecmascript-6">
import echarts from 'echarts';
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			Charts: '',
			BloodSugar: {
				index: 2,
				type: '',
				start: '',
				end: '',
				page: 1,
				size: 10,
			},
			timeData: [],
			emptyData: [],
			hourData: [],
			halfhourData: [],
			twohourData: [],
			threehourData: [],
			typeData: [],
			type: '',
			typeVal: '',
			value: '',
			valueData: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleChangeTime(value) {
			this.BloodSugar.start = value[0];
			this.BloodSugar.end = value[1];
			// this.init()
		},
		handleType(data) {
			this.BloodSugar.type = data.value;
			if (data.label === '') {
				return this.value;
			} else {
				this.value = data.label;
			}
		},
		getTypeList() {
			MemberService.checkProject().then((data) => {
				this.typeData = data.project[1].children;
				this.type = this.BloodSugar.type = data.project[1].children[0].value;
				this.typeVal = this.value = data.project[1].children[0].label;
				this.init();
			});
		},
		handleSearch() {
			this.typeVal = this.value;
			this.init();
		},
		init() {
			let self = this;
			self.BloodSugar.member_id = self.memberId;
			this.timeData = [];
			self.valueData = [];
			MemberService.sugarQuery(self.BloodSugar).then((data) => {
				data.data_show.forEach((item) => {
					self.timeData.unshift(item.create_time);
					self.valueData.unshift(item.value);
				});
				self.Charts.hideLoading();
				self.Charts.setOption({
					xAxis: {
						data: self.timeData,
					},
					series: [
						{
							// 根据名字对应到相应的系列
							name: this.typeVal,
							data: self.valueData,
						},
					],
				});
			});
		},
	},
	mounted() {
		this.getTypeList();
		// echarts实例化
		this.Charts = echarts.init(this.$refs.sugar);
		// 初始化实例
		var options = {
			title: {
				text: '整体趋势',
				textStyle: {
					color: '#424e67',
					fontSize: '16',
				},
				padding: 25,
			},
			backgroundColor: '#f1f2f7',
			grid: {
				bottom: 80,
			},
			color: ['#32d2c9'],
			// legend: {
			//   data: ['空腹血糖', '饭后半小时', '饭后一小时', '饭后两小时', '饭后三小时'],
			//   x: 'right',
			//   orient: 'vertical',
			//   padding: 25,
			//   textStyle: {
			//     color: '#666',
			//     fontSize: 12
			//   }
			// },
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					name: 'mmol/L',
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					animation: false,
					label: {
						backgroundColor: '#505765',
					},
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#32d2c9',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
			],
		};
		this.Charts.setOption(options);
		this.Charts.showLoading();
	},
};
</script>
<style lang="css" scoped>
</style>
